import type { z } from 'zod';

import { clientEnvSchema, serverEnvSchema } from '../.schema/env';
import type { EnvVars } from '../.types/envTypes';

export type ClientEnvType = z.infer<typeof clientEnvSchema>;
export type ServerEnvType = z.infer<typeof serverEnvSchema>;

type EnvReturnType = {
  clientEnvs: ClientEnvType;
  serverEnvs: ServerEnvType;
};

export function createEnv(env: EnvVars): EnvReturnType {
  return {
    clientEnvs: clientEnvSchema.parse(env),
    serverEnvs: serverEnvSchema.parse(env),
  };
}
