import { z } from 'zod';

const API_MODE = z.string().optional();
const API_URL = z.string().optional();
const CDN_LOCATION = z.string().optional();
const CI = z.string().optional();
const DEV_PORT = z.union([z.string(), z.number()]).optional();
const DOCS_URL = z.string().optional();
const DOMAIN = z.string().optional();
const FULLSTORY_ORG_ID = z.string().optional();
const GC_ENV = z
  .union([z.literal('development'), z.literal('production'), z.literal('static'), z.literal('preview')])
  .optional();
const HCAPTCHA_SECRET_KEY = z.string().optional();
const HCAPTCHA_SITE_KEY = z.string().optional();
const INTERCOM_KEY = z.string().optional();
const INTERCOM_SAFE_CODE_KEY = z.string().optional();
const NODE_ENV = z.union([z.literal('development'), z.literal('production'), z.literal('test')]).optional();
const OPENAPI_SECRET = z.string().optional();
const PLAYWRIGHT_CAPTURE_SCREENSHOTS = z.string().optional();
const PLAYWRIGHT_SCREENSHOT_DIRECTORY = z.string().optional();
const PORT = z.string().optional();
const PUSHER_KEY = z.string().optional();
const PUSHER_CLUSTER = z.string().optional();
const RECAPTCHA_CLIENT_KEY = z.string().optional();
const RECAPTCHA_SERVER_KEY = z.string().optional();
const SEGMENT_KEY = z.string().optional();
const SENTRY_APP_SSR_API_KEY = z.string().optional();
const SESSION_SECRET = z.string().optional();
const SSL_CA = z.string().optional();
const SSL_CERT = z.string().optional();
const SSL_KEY = z.string().optional();
const PLANNED_MAINTENANCE_TEXT = z.string().optional();
const UI_URL = z.string().optional();
const USER_EMAIL = z.string().optional();
const USER_PASSWORD = z.string().optional();

export const clientEnvSchema = z.object({
  API_URL,
  CDN_LOCATION,
  DEV_PORT,
  DOCS_URL,
  DOMAIN,
  FULLSTORY_ORG_ID,
  GC_ENV,
  HCAPTCHA_SECRET_KEY,
  HCAPTCHA_SITE_KEY,
  INTERCOM_KEY,
  NODE_ENV,
  PUSHER_KEY,
  PUSHER_CLUSTER,
  SEGMENT_KEY,
  SENTRY_APP_SSR_API_KEY,
  PLANNED_MAINTENANCE_TEXT,
  UI_URL,
});

export const serverEnvSchema = z.object({
  CI,
  GC_ENV,
  PLAYWRIGHT_CAPTURE_SCREENSHOTS,
  PLAYWRIGHT_SCREENSHOT_DIRECTORY,
  PORT,
  SENTRY_APP_SSR_API_KEY,
  USER_EMAIL,
  USER_PASSWORD,
  DEV_PORT,
  SSL_CA,
  SSL_KEY,
  SSL_CERT,
  INTERCOM_SAFE_CODE_KEY,
  NODE_ENV,
  SESSION_SECRET,
  API_MODE,
  API_URL,
  CDN_LOCATION,
  DOCS_URL,
  DOMAIN,
  HCAPTCHA_SECRET_KEY,
  HCAPTCHA_SITE_KEY,
  INTERCOM_KEY,
  OPENAPI_SECRET,
  RECAPTCHA_CLIENT_KEY,
  RECAPTCHA_SERVER_KEY,
  SEGMENT_KEY,
});
