import type { EnvVars } from '~/data/env/.types/envTypes';

import type { Window } from '~/components/root/.types/globalTypes';

import { createEnv } from './helpers/createEnv';

let envVars: EnvVars;
// Protect non-SSR components by using window.env when process.env isn't available
if (typeof window !== 'undefined') {
  const { env } = window as Window;
  envVars = env;
} else {
  envVars = process.env;
}

const env = createEnv(envVars);
export const clientEnvs = env.clientEnvs;
export const serverEnvs = env.serverEnvs;
